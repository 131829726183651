<template>
  <section>
    <v-container fill-height>
      <div class="col-md-4">
        <v-card>
          <v-card-title>
            Bulk Email
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <label class="caption ml-1">UPLOAD CSV FILE</label>
            <v-file-input solo dense prepend-icon=""/>
            <v-combobox
            v-model="model"
            :items="items"
            :search-input.sync="search"
            hide-selected
            hint="Maximum of 5 tags"
            label="Add some tags"
            multiple
            persistent-hint
            small-chips
          >
            <!-- <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template> -->
          </v-combobox>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    model: [''],
    items: [''],
    search: null
  })
}
</script>
